import React from "react"
import { Layout } from "../components/layout"
import styled from "styled-components"
import PageHeading from "../components/PageHeading/PageHeading"
import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import { Container } from "@material-ui/core"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
]

const Partnership: React.FC = ({ data }) => {
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)

  return (
    <Layout title={"Graduate Fashion Week - Privacy Policy"}>
      <FullWidth>
        <PageHeading breadcrumbData={breadcrumbData} pageTitle="Privacy Policy" />
      </FullWidth>
      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
    </Layout>
  )
}

export default Partnership

export const pageQuery = graphql`
  query privacy {
    wpPage(title: { eq: "Privacy Policy" }) {
      id
      lazy_data
      title
    }
  }
`

const FullWidth = styled.div`
  width: 100vw;
  /* max-width: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
